<template>
  <v-dialog
    @click:outside="$emit('closeModal')"
    v-model="show"
    max-width="340"
    hide-overlay
    transition="dialog-bottom-transition"
  >
    <v-card>
      <v-toolbar dark color="primary" flat dense>
        <v-toolbar-title>Попередній перегляд</v-toolbar-title>
        <v-spacer></v-spacer>
        <v-toolbar-items>
          <v-btn icon dark @click="$emit('closeModal')">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-toolbar-items>
      </v-toolbar>
      <div class="d-flex align-center justify-center py-2">
        <v-img :src="require('../../assets/images/phone-preview.png')" max-width="300">
          <div class="notification">
            <div class="text-uppercase text-subtitle-2 text-right">
              {{ getItemTitle(pushNotificationTypesDictionary, notification.type) }}
            </div>
            <div v-if="notification && notification.content" class="text-h6 text-truncate">
              {{ notification.content[contentIndex].title }}
            </div>
            <div v-if="notification && notification.content" class="text-caption text-truncate">
              {{ notification.content[contentIndex].body }}
            </div>
          </div>
        </v-img>
      </div>
    </v-card>
  </v-dialog>
</template>

<script>
import convertToDictionary from '@/mixins/convertToDictionary'
import { mapState } from 'vuex'

export default {
  name: 'PreviewMobileDialog',
  mixins: [convertToDictionary],
  props: {
    showModal: {
      type: Boolean,
      default: false,
      required: true,
    },
    notification: {
      type: Object,
      default: null,
      required: true,
    },
    contentIndex: {
      type: Number,
      default: 0,
      required: true,
    },
  },
  computed: {
    ...mapState('dictionaries', ['pushNotificationTypesDictionary']),

    show: {
      get() {
        return this.showModal
      },
      set() {
        this.$emit('closeModal')
      },
    },
  },
}
</script>

<style lang="scss" scoped>
.notification {
  background-color: #ffffff;
  margin: 76px 26px 0;
  padding: 4px;
  border-radius: 6px;
  max-height: 100px;
}
</style>
